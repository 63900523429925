<template>
	<section class="permission">
		<!--基本信息-->
		<el-col :span="12">
			<div class="psmMk">
				<el-form :model="pwForm" label-width="120px">
					<el-form-item label="用户名">
						<p>{{sysTrueName}}</p>
					</el-form-item>
					<el-form-item label="旧密码" prop="old_password">
						<el-input type="password" v-model="pwForm.old_password" auto-complete="off"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="password">
						<el-input type="password" v-model="pwForm.password" :maxlength="12" placeholder="密码由6-12位数字、字母、数字字母混合组成" auto-complete="off">
						</el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="re_password">
						<el-input type="password" v-model="pwForm.re_password" :maxlength="12" placeholder="密码由6-12位数字、字母、数字字母混合组成" auto-complete="off">
						</el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click.native="pwOnSubmit" :loading="Loading" icon="el-icon-circle-check">修改密码</el-button>
					<el-button @click="goBack">取 消</el-button>
				</div>
			</div>
		</el-col>
		
	</section>
</template>

<script>

	import {updatePassword} from '../../api/api';

	export default {
		data() {
			return {
				//表单数据
				pwForm: {
					old_password: '',
					password: '',
					re_password: ''
				},
				sysTrueName: '',
				Loading: false,
			}
		},
		created(){
			var user = JSON.parse(localStorage.getItem('user'));
			console.dir(user);
			this.sysTrueName = user.true_name;
			this.token = user.token;
		},
		methods: {
			//修改密码
			pwOnSubmit() {
				// var reg = /^[0-9]{6,12}|[a-zA-Z]{6,12}|[A-Za-z0-9]{6,12}$/;
				let password =this.pwForm.password;
		        if (this.pwForm.old_password ==='') {
		          this.$message.error('请输入原密码');
		          return false;
		        }else if(password === '') {
					this.$message.error('请输入新密码');
					return false;
				// }else if(reg.test(password)==false){
				}else if(password.length < 5){
					this.$message.error('密码由6-12位数字、字母、数字字母混合组成');
					return false;
				}else if(password !== this.pwForm.re_password) {
					this.$message.error('两次输入密码不一致!');
					return false;
				}
				this.pwForm.token = this.token;
				this.$confirm('确认提交吗？', '提示', {}).then(() => {
					this.addLoading = true;
					let para = Object.assign({}, this.pwForm);
					updatePassword(para).then((res) => {
						this.addLoading = false;
						if(res.code == 1) {
							this.$message({
								message: res.msg,
								type: 'success'
							});
							localStorage.clear();
							this.$router.push({name:"login"})
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
						}
					});
				});
				
			},
		},
		mounted() {}
	}
</script>